import { captureRemixErrorBoundaryError } from '@sentry/remix'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'
import {
  Link,
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from 'react-router'
import type { ErrorResponse } from 'react-router'
import { clientOnly$ } from 'vite-env-only/macros'
import { Container } from '~/components/Container'

type StatusHandler = (info: {
  error: ErrorResponse
  params: Record<string, string | undefined>
}) => React.JSX.Element | null

type GenericErrorBoundaryProps = {
  defaultStatusHandler?: StatusHandler
  statusHandlers?: Record<number, StatusHandler>
  unexpectedErrorHandler?: (error: unknown) => React.JSX.Element | null
}

export function GenericErrorBoundary({
  statusHandlers,
  defaultStatusHandler = ({ error }) => <DefaultErrorHandler error={error} />,
  unexpectedErrorHandler = (error) => <div>{getErrorMessage(error)}</div>,
}: GenericErrorBoundaryProps) {
  const params = useParams()
  const error = useRouteError()
  if (typeof document !== 'undefined' && isRouteErrorResponse(error)) {
    if (error.status !== 404) {
      captureRemixErrorBoundaryError(error)
    }
  }

  clientOnly$(NProgress.done())

  if (isRouteErrorResponse(error)) {
    return (statusHandlers?.[error.status] ?? defaultStatusHandler)({
      error,
      params,
    })
  }
  return unexpectedErrorHandler(error)
}

function DefaultErrorHandler({ error }: { error: ErrorResponse }) {
  const { t } = useTranslation()
  return (
    <Container containerClassName="w-screen h-full">
      <div className="text-center">
        <p className="text-base font-semibold text-blue-600">{error.status}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-stone-900 sm:text-5xl">
          {t('errors.generic.title')}
        </h1>
        <p className="mt-6 text-xl leading-7 text-stone-600">
          {t('errors.generic.message')}
        </p>
        <div className="flex items-center justify-center mt-10 gap-x-6">
          <Link
            to="/directory/"
            className="rounded-md no-underline bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('errors.visitTheDirectory')}
          </Link>
          <a
            href="https://helpdesk.loungepair.com/help/3983236506"
            className="text-sm font-semibold no-underline text-stone-900"
          >
            {t('errors.contactSupport')} <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </Container>
  )
}

function getErrorMessage(err: unknown) {
  const { t } = useTranslation()
  let error: string
  switch (typeof err) {
    case 'string': {
      error = err
      break
    }
    // biome-ignore lint/suspicious/noFallthroughSwitchClause: We want to fall through if the if statement doesn't evaluate
    case 'object': {
      if (err && 'message' in err && typeof err.message === 'string') {
        error = err.message
        break
      }
    }
    default: {
      console.error('Unable to get error message for error:', err)
      error = 'Unknown error'
    }
  }
  return (
    <Container containerClassName="w-screen h-screen">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-stone-900 sm:text-5xl">
          {t('errors.generic.title')}
        </h1>
        <p className="mt-6 text-xl leading-7 text-stone-600">
          {t('errors.generic.message')}
        </p>
        <div className="flex items-center justify-center mt-10 gap-x-6">
          <Link
            to="/directory/"
            className="rounded-md no-underline bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('errors.visitTheDirectory')}
          </Link>
          <a
            href="https://helpdesk.loungepair.com/help/3983236506"
            className="text-sm font-semibold no-underline text-stone-900"
          >
            {t('errors.contactSupport')} <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </Container>
  )
}
